import React from 'react';
import logo from '../logo15096841.png';

export default function MainContent({children}){
    return(
        <div >

        <div className="business-container">
            <a style={{ "textDecoration": "none" }} href="tel:1-800-310-6108">
                <img src={logo} className="business-logo" alt="" />
            </a>
        </div>

        <div className="content ">

            <div className="header">
                <ol className="steps">
                    <li className="step-pick-appointment active">
                        <span className="hidden-xs-nobabel">Select Services</span>
                    </li>
                </ol>
            </div>

            <div className="step-container">
                <div className="pane pick-appointment-pane">
                    <div className="select select-type ">

                       {children}

                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}