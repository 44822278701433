import React from 'react';

export default function SelectAppointment({ display,value ,inputOnChange, onClickButton, onKeyPress, validationError}) {

    return (
        <div style={{ display: display ? "block" : "none" }}>

            <div id="postalCode" className="form-group required-field"  >
                <span className="-b-fake-parent" data-original-text="Name ">Postal Code </span>
                <span className="error">*</span>

                <input
                    type="postalCode"
                    className="form-control"
                    name="postalCode"
                    id="postalCode"
                    // value=""
                    autoCorrect="off"
                    autoComplete="postal-code"
                    pattern="[[A-Za-z]\d[[A-Za-z] ?\d[[A-Za-z]\d ?"
                    title="Please enter a valid postal code"
                    required
                    onChange={inputOnChange}
                    onKeyPress={onKeyPress}
                    value={value}
                    
                />
            </div>
                <p className="validationMessage">{validationError}</p>

            <input 
            id="submitButton" 
            type="submit" 
            className="btn btn-primary submit-forms-nopay" 
            value="Choose Appointment »" 
            onClick={onClickButton}
            />

        </div>
    )
}