import React from 'react';

export default function ServiceRepair({display}) {
    return (
        <a
            className="select-item-box select-item"
            href="https://andmarecoenergy.as.me/?appointmentType=category:Service+%26+Repair"
            style={{ display: display ? "block" : "none" }}
        >
            <label className="babel-ignore" >

                <div id="service-repair" className="link-container" >
                    <label style={{ "display": "block", "textAlign": "left" }} className="select-item babel-ignore" >
                        <span className="appointment-type-name" >Service  Repair</span>
                        <div className="type-description text-muted"></div>
                    </label>
                </div>
            </label>
        </a>
    )
}