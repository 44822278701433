
import { useState, useRef } from 'react';
import ServiceRepair from './component/ServiceRepair';
import SelectAppointment from './component/SelectAppointment';
import useOutsideClick from './hooks/useOutsideClick';
import FreeEstimate from './component/FreeEstimate';
import MainContent from './component/MainContent';
import { SpinnerComponent } from 'react-element-spinner';


function App() {

  const [isFreeEstimateActive, setIsFreeEstimateActive] = useState(false);
    const [postalCode, setPostalCode] = useState("");
    const [validationError, setValidationError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const ref = useRef();
    
    useOutsideClick(ref, () => setIsFreeEstimateActive(false));

    const onKeyPress=(event)=>{ if (event.key === "Enter") { handleClickButton() } };

    const handleInputChange=(e)=>{
        const value = e.target.value.toUpperCase().replace(/\s+/g, '');
        setPostalCode(value);
        if(/^(?!.*[DFIOQU])[A-VXY][0-9][A-Z]●?[0-9][A-Z][0-9]$/.test(value)){
        // if(/[[A-Za-z]\d[[A-Za-z] ?\d[[A-Za-z]\d ?/.test(value)){
        setValidationError("");
        }else{setValidationError("invalid postal code");}

    }

    const handleClickButton = async()=>{ 
      if(validationError==="" && postalCode !==""){
      setIsLoading(true);
      const options = { mode:'cors',method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ "postalCode":postalCode }) };
      const res = await fetch('https://wxcvcscd4i.execute-api.ca-central-1.amazonaws.com/dev/getAsCalendarByPostalCode', options) ;
      const {url, message} = await res.json();
      if(message !== ""){
        setValidationError(message);
        setIsLoading(false);
      }else{
        window.location.href = url;
        setIsLoading(false);
      }
      }
    }

  return (
    <>
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <MainContent>
                <div ref={ref}>
                    <FreeEstimate cursor={isFreeEstimateActive} onClick={() => setIsFreeEstimateActive(true)} >
                        <SelectAppointment
                            display={isFreeEstimateActive}
                            inputOnChange={handleInputChange}
                            onClickButton={handleClickButton}
                            onKeyPress={onKeyPress}
                            validationError={validationError}
                            value={postalCode}
                        />
                        
                    </FreeEstimate>
                </div>
                <ServiceRepair display={!isFreeEstimateActive} />
            </MainContent>
            <SpinnerComponent loading={isLoading} position="global" />
    </>
  );
}

export default App;
