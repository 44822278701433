import React from 'react';

export default function FreeEstimate({children,onClick, cursor }){
    return(
        <div className={`select-item-box select-item ${cursor}`} onClick={onClick}>
        <label className="babel-ignore" >

            <div id="freeEstimate" className="link-container" style={{ "display": "block" }}  >
                <label style={{ "display": "block", "textAlign": "left" }} className="select-item babel-ignore " >
                    <span className="appointment-type-name" >Free Estimate</span>
                    <div className="type-description text-muted"></div>
                </label>
            </div>

            {children}
        </label>
    </div>
    )
}